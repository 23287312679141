<template>
  <v-navigation-drawer
    id="default-drawer"
    :mini-variant="$store.state.isDrawerOpened"
    fixed
    permanent
    app
  >
    <PeopleSections v-if="$route.path.includes('people')" />
    <StockSections v-if="$route.path.includes('stock')" />
    <ComercialSections v-if="$route.path.includes('comercial')" />
    <SiteSections v-if="$route.path.includes('site')" />
  </v-navigation-drawer>
</template>

<script>
import PeopleSections from './drawer-sections/PeopleSections.vue'
import StockSections from './drawer-sections/StockSections.vue'
import ComercialSections from './drawer-sections/ComercialSections.vue'
import SiteSections from './drawer-sections/SiteSections.vue'

export default {
  name: 'Drawer',
  components: {
    PeopleSections,
    StockSections,
    ComercialSections,
    SiteSections,
  },
}
</script>

<style lang="scss">
.active::before {
  background: none;
}
.active {
  background: linear-gradient(90deg, #0f5baa, #2a85e6) !important;
  color: white !important;
}
</style>
